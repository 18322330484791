import { render, staticRenderFns } from "./category_lists.vue?vue&type=template&id=480c2e45&scoped=true&"
import script from "./category_lists.vue?vue&type=script&lang=ts&"
export * from "./category_lists.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "480c2e45",
  null
  
)

export default component.exports