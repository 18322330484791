
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import {
    apiArticleCategoryLists,
    apiArticleCategoryDelete,
    apiArticleCategoryIsShow
} from '@/api/article'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import ArticleCategoryEdit from './category_edit.vue'

@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData,
        ArticleCategoryEdit
    }
})
export default class HelpManage extends Vue {
    /** S Data **/

    // 分页
    pager: RequestPaging = new RequestPaging()

    /** E Data **/

    /** S Methods **/

    // 更改显示开关状态
    changeStatus(value: 0 | 1, row: any) {
        apiArticleCategoryIsShow({
            id: row.id
        })
            .then(() => {
                this.getCategoryList()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 获取分类列表
    getCategoryList() {
        this.pager
            .request({
                callback: apiArticleCategoryLists
                // params: {},
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 删除该分类
    onCategoryDel(row: any) {
        apiArticleCategoryDelete({
            id: row.id
        }).then(() => {
            this.getCategoryList()
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getCategoryList()
    }

    /** E Life Cycle **/
}
